@charset "UTF-8";
/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/*
  Generic styling for the mega-menu for it to be display as a list of elements
  Target the dgs-mega-menu, which is build up on nested unordered lists

  IMPORTANT: If you want a specific change for the mega-menu add it on a project file.
 */
#wrapper ul.dgs-mega-menu,
#wrapper ul.dgs-mega-menu ul {
  list-style: none; }

#wrapper ul.dgs-mega-menu > li {
  padding: 0 1rem;
  display: inline-block; }
  #wrapper ul.dgs-mega-menu > li > a {
    display: inline-block;
    padding: 1rem 0;
    position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active {
      position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active:before, #wrapper ul.dgs-mega-menu > li > a.current:before {
      height: 0.3rem;
      background-color: lightgrey;
      visibility: visible;
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: scaleX(1); }
  #wrapper ul.dgs-mega-menu > li > ul {
    width: 100%;
    display: none;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    padding: 2.5rem 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.25rem 0; }
    #wrapper ul.dgs-mega-menu > li > ul a {
      display: inline-block;
      padding: 0.5rem 0; }
      #wrapper ul.dgs-mega-menu > li > ul a.as-text {
        cursor: default; }
    #wrapper ul.dgs-mega-menu > li > ul > li {
      display: inline-block;
      padding: 0 3rem;
      overflow-wrap: break-word;
      border-right: 0.05rem solid lightgrey;
      box-sizing: border-box;
      /* One - two items */
      /* Three elements */
      /* Four items */
      /* Five...seven items */ }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1), #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li {
        width: 50%; }
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1) > ul, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) > ul,
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li > ul {
          columns: 2; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4) ~ li {
        width: 25%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7) ~ li {
        width: 20%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:nth-child(n+6) {
        display: none; }
      #wrapper ul.dgs-mega-menu > li > ul > li:last-child {
        border-right: 0; }
      #wrapper ul.dgs-mega-menu > li > ul > li > a {
        font-weight: bold;
        padding-bottom: 0.5rem; }
      #wrapper ul.dgs-mega-menu > li > ul > li ul a {
        font-weight: normal; }

ul.dgs-burger-menu {
  list-style: none;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  ul.dgs-burger-menu, ul.dgs-burger-menu li:not(.last-navigation) > ul:not(.last-navigation) {
    list-style: none;
    background-color: white;
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 2rem; }
  ul.dgs-burger-menu li {
    padding: 0.8rem 0; }
  ul.dgs-burger-menu a.as-text {
    cursor: default; }
  ul.dgs-burger-menu > li > ul {
    z-index: 1;
    top: 0; }
  ul.dgs-burger-menu.text-center li > a {
    width: 90%;
    margin: auto;
    padding: 0.5rem 0.2rem;
    display: block;
    text-align: center; }
  ul.dgs-burger-menu.text-left li {
    text-align: left; }
    ul.dgs-burger-menu.text-left li > a {
      width: 90%;
      margin: 0 0 0 1.5rem;
      padding: 0.5rem 0.2rem;
      display: inline-block; }
  ul.dgs-burger-menu.text-right li {
    text-align: right; }
    ul.dgs-burger-menu.text-right li > a {
      width: 90%;
      margin: 0 1.5rem 0 0;
      padding: 0.5rem 0.2rem;
      display: inline-block; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  background: white;
  position: relative;
  overflow: auto; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

* {
  box-sizing: border-box; }

select::-ms-expand {
  /* for IE 11 */
  display: none; }

.component.image-spot.slim .component-content, .component.image-spot .component-content, .component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.image-spot.slim .component-content {
  width: 77rem; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 15px !important; } }

html, body {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  font-size: 1rem;
  color: #0e1111;
  background-color: #fff; }

h1, .like-h1 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.3;
  color: #6BCABA; }
  @media all and (-ms-high-contrast: none) {
    h1, .like-h1 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h1, .like-h1 {
      font-size: 2rem; } }

h2, .like-h2 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.2;
  color: #6BCABA; }
  @media all and (-ms-high-contrast: none) {
    h2, .like-h2 {
      font-weight: bolder; } }
  @media only screen and (max-width: 767px) {
    h2, .like-h2 {
      font-size: 1.5rem; } }

h3, .like-h3 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.333rem;
  line-height: 1.3;
  color: #0e1111; }
  @media all and (-ms-high-contrast: none) {
    h3, .like-h3 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h3, .like-h3 {
      font-size: 1.25rem; } }

h4, .like-h4 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.333rem;
  line-height: 1.3;
  color: #0e1111; }
  @media all and (-ms-high-contrast: none) {
    h4, .like-h4 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h4, .like-h4 {
      font-size: 1rem; } }

p {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  font-size: 1rem; }

strong {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal; }

pre {
  padding: 2rem;
  background-color: #4EC3E0;
  color: #0e1111; }

h1 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h1 {
      margin-top: 0.5rem; } }

h2 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h1 {
      margin-top: 0.66667rem; } }

h3 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h1 {
      margin-top: 0.66667rem; } }

h4 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h1 {
      margin-top: 0.66667rem; } }

h5 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h1 {
      margin-top: 0.66667rem; } }

p + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h1 {
      margin-top: 0.66667rem; } }

ol + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h1 {
      margin-top: 0.66667rem; } }

ul + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h1 {
      margin-top: 0.66667rem; } }

h1 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h2 {
      margin-top: 0.5rem; } }

h2 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h2 {
      margin-top: 0.66667rem; } }

h3 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h2 {
      margin-top: 0.66667rem; } }

h4 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h2 {
      margin-top: 0.66667rem; } }

h5 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h2 {
      margin-top: 0.66667rem; } }

p + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h2 {
      margin-top: 0.66667rem; } }

ol + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h2 {
      margin-top: 0.66667rem; } }

ul + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h2 {
      margin-top: 0.66667rem; } }

h1 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h3 {
      margin-top: 0.5rem; } }

h2 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h3 {
      margin-top: 0.66667rem; } }

h3 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h3 {
      margin-top: 0.66667rem; } }

h4 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h3 {
      margin-top: 0.66667rem; } }

h5 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h3 {
      margin-top: 0.66667rem; } }

p + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h3 {
      margin-top: 0.66667rem; } }

ol + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h3 {
      margin-top: 0.66667rem; } }

ul + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h3 {
      margin-top: 0.66667rem; } }

h1 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h4 {
      margin-top: 0.5rem; } }

h2 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h4 {
      margin-top: 0.66667rem; } }

h3 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h4 {
      margin-top: 0.66667rem; } }

h4 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h4 {
      margin-top: 0.66667rem; } }

h5 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h4 {
      margin-top: 0.66667rem; } }

p + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h4 {
      margin-top: 0.66667rem; } }

ol + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h4 {
      margin-top: 0.66667rem; } }

ul + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h4 {
      margin-top: 0.66667rem; } }

h1 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h5 {
      margin-top: 0.5rem; } }

h2 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h5 {
      margin-top: 0.66667rem; } }

h3 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h5 {
      margin-top: 0.66667rem; } }

h4 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h5 {
      margin-top: 0.66667rem; } }

h5 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h5 {
      margin-top: 0.66667rem; } }

p + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h5 {
      margin-top: 0.66667rem; } }

ol + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h5 {
      margin-top: 0.66667rem; } }

ul + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h5 {
      margin-top: 0.66667rem; } }

h1 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + p {
      margin-top: 0.5rem; } }

h2 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + p {
      margin-top: 0.66667rem; } }

h3 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + p {
      margin-top: 0.66667rem; } }

h4 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + p {
      margin-top: 0.66667rem; } }

h5 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + p {
      margin-top: 0.66667rem; } }

p + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + p {
      margin-top: 0.66667rem; } }

ol + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + p {
      margin-top: 0.66667rem; } }

ul + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + p {
      margin-top: 0.66667rem; } }

h1 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ol {
      margin-top: 0.5rem; } }

h2 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ol {
      margin-top: 0.66667rem; } }

h3 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ol {
      margin-top: 0.66667rem; } }

h4 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ol {
      margin-top: 0.66667rem; } }

h5 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ol {
      margin-top: 0.66667rem; } }

p + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ol {
      margin-top: 0.66667rem; } }

ol + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ol {
      margin-top: 0.66667rem; } }

ul + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ol {
      margin-top: 0.66667rem; } }

h1 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ul {
      margin-top: 0.5rem; } }

h2 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ul {
      margin-top: 0.66667rem; } }

h3 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ul {
      margin-top: 0.66667rem; } }

h4 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ul {
      margin-top: 0.66667rem; } }

h5 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ul {
      margin-top: 0.66667rem; } }

p + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ul {
      margin-top: 0.66667rem; } }

ol + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ul {
      margin-top: 0.66667rem; } }

ul + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ul {
      margin-top: 0.66667rem; } }

a, .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4EC3E0;
  text-decoration: underline; }
  a:hover, .like-a:hover {
    color: #4EC3E0; }

.like-a-negative {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4EC3E0;
  text-decoration: underline;
  color: #4EC3E0; }
  .like-a-negative:hover {
    color: #4EC3E0; }
  .like-a-negative:hover {
    color: #4EC3E0; }

label {
  display: block;
  padding-bottom: 0.5rem; }

input:not([type='radio']):not([type='checkbox']):not([type='submit']),
textarea,
select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 3px solid #4EC3E0;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #0e1111;
  line-height: 1;
  background-color: transparent; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
  textarea:focus,
  select:focus {
    border-color: #6BCABA; }

input[type="submit"],
button,
.like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #6BCABA;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  input[type="submit"]:not(.disabled),
  button:not(.disabled),
  .like-button:not(.disabled) {
    cursor: pointer; }
  input[type="submit"]:not(.disabled):hover,
  button:not(.disabled):hover,
  .like-button:not(.disabled):hover {
    background-color: #6BCABA;
    color: #ffffff; }
    input[type="submit"]:not(.disabled):hover a,
    button:not(.disabled):hover a,
    .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  input[type="submit"] a,
  button a,
  .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.like-button-negative {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #ADDC91;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #4B4F54;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-negative:not(.disabled) {
    cursor: pointer; }
  .like-button-negative:not(.disabled):hover {
    background-color: #ADDC91;
    color: #4B4F54; }
    .like-button-negative:not(.disabled):hover a {
      text-decoration: none;
      color: #4B4F54;
      text-shadow: none; }
  .like-button-negative a {
    color: #4B4F54;
    text-decoration: none;
    background-color: transparent; }

::selection {
  background: #ADDC91;
  color: #00558C; }

[id="content"] .component {
  opacity: 0;
  transition: opacity 1s; }
  [id="content"] .component.hide {
    display: none; }

[id="footer"] {
  opacity: 0;
  transition: opacity 1s; }

[id="content"] .component.component-ready.animate,
[id="footer"].animate {
  opacity: 1; }

[id="content"] .component.component-failed {
  display: none !important; }

[id="content"] .component, [id="footer"] .component {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component, [id="footer"] .component {
      padding-top: 1rem; } }
  [id="content"] .component.repeating-background, [id="footer"] .component.repeating-background {
    padding-top: 0; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component, [id="footer"] .component {
      padding-bottom: 1rem; } }

ul, ol {
  list-style-position: inside; }
  ul li, ol li {
    line-height: 1.4;
    text-indent: -1rem;
    padding-left: 1rem; }
    ul li + li, ol li + li {
      margin-top: 0.5rem; }

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none; } }

.clearfix {
  clear: both; }
  .clearfix:before, .clearfix:after {
    content: '';
    display: table; }

.bg-color-1 {
  background-color: #ADDC91; }

.text-color-1 {
  color: #ADDC91; }

.bg-color-2 {
  background-color: #6BCABA; }

.text-color-2 {
  color: #6BCABA; }

.bg-color-3 {
  background-color: #4EC3E0; }

.text-color-3 {
  color: #4EC3E0; }

.bg-color-4 {
  background-color: #00558C; }

.text-color-4 {
  color: #00558C; }

.bg-color-5 {
  background-color: #D7D2CB; }

.text-color-5 {
  color: #D7D2CB; }

.bg-color-6 {
  background-color: #4B4F54; }

.text-color-6 {
  color: #4B4F54; }

.bg-color-7 {
  background-color: #0e1111; }

.text-color-7 {
  color: #0e1111; }

.bg-color-8 {
  background-color: #ffffff; }

.text-color-8 {
  color: #ffffff; }

.negative-elements a, .negative-elements .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4EC3E0;
  text-decoration: underline;
  color: #4EC3E0; }
  .negative-elements a:hover, .negative-elements .like-a:hover {
    color: #4EC3E0; }
  .negative-elements a:hover, .negative-elements .like-a:hover {
    color: #4EC3E0; }

.negative-elements input[type="submit"],
.negative-elements button,
.negative-elements .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #ADDC91;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #4B4F54;
  font-weight: 700;
  font-size: 1.33rem; }
  .negative-elements input[type="submit"]:not(.disabled),
  .negative-elements button:not(.disabled),
  .negative-elements .like-button:not(.disabled) {
    cursor: pointer; }
  .negative-elements input[type="submit"]:not(.disabled):hover,
  .negative-elements button:not(.disabled):hover,
  .negative-elements .like-button:not(.disabled):hover {
    background-color: #ADDC91;
    color: #4B4F54; }
    .negative-elements input[type="submit"]:not(.disabled):hover a,
    .negative-elements button:not(.disabled):hover a,
    .negative-elements .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #4B4F54;
      text-shadow: none; }
  .negative-elements input[type="submit"] a,
  .negative-elements button a,
  .negative-elements .like-button a {
    color: #4B4F54;
    text-decoration: none;
    background-color: transparent; }

.negative-elements input:not([type='radio']):not([type='checkbox']):not([type='submit']),
.negative-elements textarea,
.negative-elements select {
  color: #4B4F54;
  border-color: #6BCABA; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.nice-select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #4EC3E0;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #0e1111;
  line-height: 1;
  border-radius: 0;
  height: 3.35rem;
  float: none; }
  .nice-select:active, .nice-select.open, .nice-select.open, .nice-select:focus, .nice-select:hover {
    border-color: #4EC3E0; }
  .nice-select > .current {
    display: inline-block;
    line-height: 1.5; }
  .nice-select:after {
    border-bottom: 2px solid #4EC3E0;
    border-right: 2px solid #4EC3E0; }
  .nice-select > .list {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 0 2px #4EC3E0;
    margin-top: 2px; }
    .nice-select > .list .option {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      line-height: 1;
      text-indent: 0; }
  .resource-center .nice-select {
    min-width: 13.8rem; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.like-button-negative {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #00558C;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-negative:not(.disabled) {
    cursor: pointer; }
  .like-button-negative:not(.disabled):hover {
    background-color: #00558C;
    color: #ffffff; }
    .like-button-negative:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .like-button-negative a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.like-button-spring-green {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #ADDC91;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #0e1111;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-spring-green:not(.disabled) {
    cursor: pointer; }
  .like-button-spring-green:not(.disabled):hover {
    background-color: #ADDC91;
    color: #0e1111; }
    .like-button-spring-green:not(.disabled):hover a {
      text-decoration: none;
      color: #0e1111;
      text-shadow: none; }
  .like-button-spring-green a {
    color: #0e1111;
    text-decoration: none;
    background-color: transparent; }

.like-button-sky-blue {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #4EC3E0;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-sky-blue:not(.disabled) {
    cursor: pointer; }
  .like-button-sky-blue:not(.disabled):hover {
    background-color: #4EC3E0;
    color: #ffffff; }
    .like-button-sky-blue:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .like-button-sky-blue a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.negative-elements .like-button-sky-blue {
  color: #ffffff; }

.like-button-warm-grey {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #D7D2CB;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #0e1111;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-warm-grey:not(.disabled) {
    cursor: pointer; }
  .like-button-warm-grey:not(.disabled):hover {
    background-color: #D7D2CB;
    color: #0e1111; }
    .like-button-warm-grey:not(.disabled):hover a {
      text-decoration: none;
      color: #0e1111;
      text-shadow: none; }
  .like-button-warm-grey a {
    color: #0e1111;
    text-decoration: none;
    background-color: transparent; }

.like-button-antracit {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #4B4F54;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .like-button-antracit:not(.disabled) {
    cursor: pointer; }
  .like-button-antracit:not(.disabled):hover {
    background-color: #4B4F54;
    color: #ffffff; }
    .like-button-antracit:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .like-button-antracit a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

h5 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 800;
  font-style: normal; }
  @media all and (-ms-high-contrast: none) {
    h5 {
      font-weight: bolder; } }

ul > li ul > li {
  list-style: disc;
  list-style-position: inside; }

.content .bg-color-1 input[type="submit"],
.content .bg-color-1 button,
.content .bg-color-1 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #ADDC91;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-1 input[type="submit"]:not(.disabled),
  .content .bg-color-1 button:not(.disabled),
  .content .bg-color-1 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-1 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-1 button:not(.disabled):hover,
  .content .bg-color-1 .like-button:not(.disabled):hover {
    background-color: #ADDC91;
    color: #ffffff; }
    .content .bg-color-1 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-1 button:not(.disabled):hover a,
    .content .bg-color-1 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-1 input[type="submit"] a,
  .content .bg-color-1 button a,
  .content .bg-color-1 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-1.negative-elements h1,
.content .bg-color-1.negative-elements h2,
.content .bg-color-1.negative-elements h3,
.content .bg-color-1.negative-elements h4,
.content .bg-color-1.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-2 input[type="submit"],
.content .bg-color-2 button,
.content .bg-color-2 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #6BCABA;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-2 input[type="submit"]:not(.disabled),
  .content .bg-color-2 button:not(.disabled),
  .content .bg-color-2 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-2 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-2 button:not(.disabled):hover,
  .content .bg-color-2 .like-button:not(.disabled):hover {
    background-color: #6BCABA;
    color: #ffffff; }
    .content .bg-color-2 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-2 button:not(.disabled):hover a,
    .content .bg-color-2 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-2 input[type="submit"] a,
  .content .bg-color-2 button a,
  .content .bg-color-2 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-2.negative-elements h1,
.content .bg-color-2.negative-elements h2,
.content .bg-color-2.negative-elements h3,
.content .bg-color-2.negative-elements h4,
.content .bg-color-2.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-3 input[type="submit"],
.content .bg-color-3 button,
.content .bg-color-3 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #4EC3E0;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-3 input[type="submit"]:not(.disabled),
  .content .bg-color-3 button:not(.disabled),
  .content .bg-color-3 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-3 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-3 button:not(.disabled):hover,
  .content .bg-color-3 .like-button:not(.disabled):hover {
    background-color: #4EC3E0;
    color: #ffffff; }
    .content .bg-color-3 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-3 button:not(.disabled):hover a,
    .content .bg-color-3 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-3 input[type="submit"] a,
  .content .bg-color-3 button a,
  .content .bg-color-3 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-3.negative-elements h1,
.content .bg-color-3.negative-elements h2,
.content .bg-color-3.negative-elements h3,
.content .bg-color-3.negative-elements h4,
.content .bg-color-3.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-4 input[type="submit"],
.content .bg-color-4 button,
.content .bg-color-4 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #00558C;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-4 input[type="submit"]:not(.disabled),
  .content .bg-color-4 button:not(.disabled),
  .content .bg-color-4 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-4 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-4 button:not(.disabled):hover,
  .content .bg-color-4 .like-button:not(.disabled):hover {
    background-color: #00558C;
    color: #ffffff; }
    .content .bg-color-4 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-4 button:not(.disabled):hover a,
    .content .bg-color-4 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-4 input[type="submit"] a,
  .content .bg-color-4 button a,
  .content .bg-color-4 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-4.negative-elements h1,
.content .bg-color-4.negative-elements h2,
.content .bg-color-4.negative-elements h3,
.content .bg-color-4.negative-elements h4,
.content .bg-color-4.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-5 input[type="submit"],
.content .bg-color-5 button,
.content .bg-color-5 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #D7D2CB;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-5 input[type="submit"]:not(.disabled),
  .content .bg-color-5 button:not(.disabled),
  .content .bg-color-5 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-5 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-5 button:not(.disabled):hover,
  .content .bg-color-5 .like-button:not(.disabled):hover {
    background-color: #D7D2CB;
    color: #ffffff; }
    .content .bg-color-5 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-5 button:not(.disabled):hover a,
    .content .bg-color-5 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-5 input[type="submit"] a,
  .content .bg-color-5 button a,
  .content .bg-color-5 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-5.negative-elements h1,
.content .bg-color-5.negative-elements h2,
.content .bg-color-5.negative-elements h3,
.content .bg-color-5.negative-elements h4,
.content .bg-color-5.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-6 input[type="submit"],
.content .bg-color-6 button,
.content .bg-color-6 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #4B4F54;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-6 input[type="submit"]:not(.disabled),
  .content .bg-color-6 button:not(.disabled),
  .content .bg-color-6 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-6 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-6 button:not(.disabled):hover,
  .content .bg-color-6 .like-button:not(.disabled):hover {
    background-color: #4B4F54;
    color: #ffffff; }
    .content .bg-color-6 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-6 button:not(.disabled):hover a,
    .content .bg-color-6 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-6 input[type="submit"] a,
  .content .bg-color-6 button a,
  .content .bg-color-6 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-6.negative-elements h1,
.content .bg-color-6.negative-elements h2,
.content .bg-color-6.negative-elements h3,
.content .bg-color-6.negative-elements h4,
.content .bg-color-6.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-7 input[type="submit"],
.content .bg-color-7 button,
.content .bg-color-7 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #0e1111;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-7 input[type="submit"]:not(.disabled),
  .content .bg-color-7 button:not(.disabled),
  .content .bg-color-7 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-7 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-7 button:not(.disabled):hover,
  .content .bg-color-7 .like-button:not(.disabled):hover {
    background-color: #0e1111;
    color: #ffffff; }
    .content .bg-color-7 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-7 button:not(.disabled):hover a,
    .content .bg-color-7 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-7 input[type="submit"] a,
  .content .bg-color-7 button a,
  .content .bg-color-7 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-7.negative-elements h1,
.content .bg-color-7.negative-elements h2,
.content .bg-color-7.negative-elements h3,
.content .bg-color-7.negative-elements h4,
.content .bg-color-7.negative-elements h5 {
  color: #ffffff; }

.content .bg-color-8 input[type="submit"],
.content .bg-color-8 button,
.content .bg-color-8 .like-button {
  display: inline-block;
  border-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  padding: 0.9375rem 2rem;
  text-decoration: none;
  background-color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.33rem; }
  .content .bg-color-8 input[type="submit"]:not(.disabled),
  .content .bg-color-8 button:not(.disabled),
  .content .bg-color-8 .like-button:not(.disabled) {
    cursor: pointer; }
  .content .bg-color-8 input[type="submit"]:not(.disabled):hover,
  .content .bg-color-8 button:not(.disabled):hover,
  .content .bg-color-8 .like-button:not(.disabled):hover {
    background-color: #ffffff;
    color: #ffffff; }
    .content .bg-color-8 input[type="submit"]:not(.disabled):hover a,
    .content .bg-color-8 button:not(.disabled):hover a,
    .content .bg-color-8 .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #ffffff;
      text-shadow: none; }
  .content .bg-color-8 input[type="submit"] a,
  .content .bg-color-8 button a,
  .content .bg-color-8 .like-button a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent; }

.content .bg-color-8.negative-elements h1,
.content .bg-color-8.negative-elements h2,
.content .bg-color-8.negative-elements h3,
.content .bg-color-8.negative-elements h4,
.content .bg-color-8.negative-elements h5 {
  color: #ffffff; }

[id="content"] .component,
[id="footer"] {
  opacity: 1; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#header .row {
  position: relative;
  overflow: hidden; }
  #header .row::after {
    position: absolute;
    display: block;
    content: "";
    background-color: #ffffff;
    height: 47vw;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 130vw;
    left: -15%;
    bottom: -38vw; }
  @media only screen and (min-width: 768px) {
    #header .row::after {
      display: none; } }
  #header .row.with-sub-navigation::after {
    background-color: #00558C; }

#header {
  opacity: 1;
  /*@include col-xs() {
      .level2-wrapper {
          overflow: hidden;
          position: absolute;
          top: 427px;
          width: 100%;
      }
    }*/ }
  #header .component.image:first-of-type .component-content img {
    height: auto;
    left: 40px;
    max-width: 180px;
    top: 20px;
    position: fixed;
    z-index: 101; }
    @media only screen and (min-width: 992px) {
      #header .component.image:first-of-type .component-content img {
        max-width: 200px; } }
    @media only screen and (max-width: 767px) {
      #header .component.image:first-of-type .component-content img {
        max-width: 160px; } }
  #header .component.image:last-of-type {
    overflow: hidden; }
    #header .component.image:last-of-type .component-content {
      position: relative; }
      #header .component.image:last-of-type .component-content img {
        max-width: 100%;
        height: auto;
        width: 100%; }
      #header .component.image:last-of-type .component-content a,
      #header .component.image:last-of-type .component-content img {
        display: block; }
  #header .component.navigation {
    position: fixed;
    width: 100%;
    z-index: 100;
    padding: 28px 0;
    background-color: #ADDC91;
    display: block;
    text-align: right;
    min-height: 90px; }
    #header .component.navigation .component-content > ul {
      display: inline-block;
      margin-right: 5%; }
      @media only screen and (min-width: 992px) {
        #header .component.navigation .component-content > ul {
          margin-left: calc(5% + 220px);
          margin-right: 10%; } }
      @media only screen and (max-width: 767px) {
        #header .component.navigation .component-content > ul {
          left: -100%;
          z-index: 1;
          position: fixed;
          transition-duration: .5s;
          bottom: 0; }
          #header .component.navigation .component-content > ul.menu-open {
            border-top: 1px solid #00558C;
            width: 100%;
            display: block;
            left: 0;
            background-color: #ADDC91;
            color: #00558C;
            padding: 10px 10px 40px 50px;
            margin-right: 0; } }
      #header .component.navigation .component-content > ul li {
        font-family: "Open Sans", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.333rem;
        line-height: 1.3;
        color: #0e1111;
        text-indent: 0;
        font-size: 1rem; }
        @media all and (-ms-high-contrast: none) {
          #header .component.navigation .component-content > ul li {
            font-weight: bold; } }
        @media only screen and (max-width: 767px) {
          #header .component.navigation .component-content > ul li {
            font-size: 1.25rem; } }
        @media only screen and (min-width: 992px) {
          #header .component.navigation .component-content > ul li {
            font-size: 1.33rem; } }
        #header .component.navigation .component-content > ul li a {
          font-weight: 700;
          color: #00558C;
          text-decoration: none;
          opacity: 0.8; }
          #header .component.navigation .component-content > ul li a.active {
            opacity: 1; }
    #header .component.navigation .component-content li.level1 {
      display: inline-block; }
      @media only screen and (max-width: 767px) {
        #header .component.navigation .component-content li.level1 {
          display: block;
          margin-right: 30px;
          margin-top: 20px; } }
    #header .component.navigation .component-content li.level2 {
      display: none; }
      #header .component.navigation .component-content li.level2.active {
        display: inline-block; }
  #header .level2-container {
    display: none; }
    #header .level2-container.level2con-active {
      padding: 0 0 28px 0;
      background-color: #00558C;
      display: block;
      text-align: center;
      /*            @include col-xs(){
                width: 130%;
                left: -15%;
                position: relative;
                border-top-left-radius: 100%;
                border-top-right-radius: 100%;
            }*/ }
      @media only screen and (min-width: 768px) {
        #header .level2-container.level2con-active {
          padding-top: 28px; } }
      #header .level2-container.level2con-active > ul {
        display: block; }
        @media only screen and (min-width: 992px) {
          #header .level2-container.level2con-active > ul {
            margin: 0 auto; } }
        #header .level2-container.level2con-active > ul li {
          font-family: "Open Sans", "Arial", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 1.333rem;
          line-height: 1.3;
          color: #0e1111;
          text-indent: 0;
          display: inline-block;
          font-size: 1rem;
          padding-left: 1.33rem; }
          @media all and (-ms-high-contrast: none) {
            #header .level2-container.level2con-active > ul li {
              font-weight: bold; } }
          @media only screen and (max-width: 767px) {
            #header .level2-container.level2con-active > ul li {
              font-size: 1.25rem; } }
          #header .level2-container.level2con-active > ul li:first-of-type {
            padding-left: 0; }
          @media only screen and (min-width: 992px) {
            #header .level2-container.level2con-active > ul li {
              font-size: 1.33rem; } }
          @media only screen and (max-width: 767px) {
            #header .level2-container.level2con-active > ul li {
              display: block;
              font-size: 1.33rem; } }
          #header .level2-container.level2con-active > ul li a {
            font-weight: 700;
            color: #ffffff;
            text-decoration: none; }
            #header .level2-container.level2con-active > ul li a.active {
              color: #ADDC91; }
  @media only screen and (max-width: 767px) {
    #header .mobile-nav {
      display: inline-block;
      margin-right: 40px;
      width: 37px;
      height: 25px;
      border-bottom: 3.5px solid #ffffff; }
      #header .mobile-nav::before {
        content: "";
        border-bottom: 3.5px solid #ffffff;
        border-top: 3.5px solid #ffffff;
        height: 8px;
        position: absolute;
        width: 37px;
        border-radius: 0;
        margin-left: -37px; }
      #header .mobile-nav.menu-open {
        transform: rotate(45deg) translate(-5px, 5px);
        margin-right: 24px; }
        #header .mobile-nav.menu-open::before {
          top: 11px;
          border-bottom: 0;
          transform: rotate(-90deg) translate(-5px, 5px); } }

.component.intro-banner .component-content .intro-banner-image img {
  max-width: 100%; }

#footer .component.rich-text-list h3 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 800;
  font-style: normal; }
  @media all and (-ms-high-contrast: none) {
    #footer .component.rich-text-list h3 {
      font-weight: bolder; } }

#footer .component.rich-text-list a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  color: #00558C;
  font-size: 1.67rem; }
  @media all and (-ms-high-contrast: none) {
    #footer .component.rich-text-list a {
      font-weight: bold; } }
  #footer .component.rich-text-list a:hover {
    color: #00558C; }

#footer .component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
  border-bottom: none; }

#footer .component.rich-text-list .component-content > ul > li:last-child p a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  font-size: 1rem;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal; }

#footer .component.rich-text-list .component-content p {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem; }

#footer .component.rich-text-list .component-content strong {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1rem; }
  @media all and (-ms-high-contrast: none) {
    #footer .component.rich-text-list .component-content strong {
      font-weight: bolder; } }

#footer .component.rich-text-list .component-content .cookie-links {
  margin-top: 0; }

#footer .component.rich-text-list .component-content .cookie-text {
  display: inline-block; }
  @media screen and (max-width: 992px) {
    #footer .component.rich-text-list .component-content .cookie-text {
      margin-top: 0; } }

@media screen and (min-width: 1400px) {
  #footer .component.rich-text-list .component-content .cookie-links {
    display: inline-block;
    float: right;
    margin-top: 1.5rem; }
  #footer .component.rich-text-list .component-content .cookie-text {
    margin-top: 1.5rem; } }

#footer .component.rich-text-list .component-content p.text-container:after {
  content: "";
  display: inline-block;
  background-size: contain;
  width: 160px;
  height: 40px;
  margin-top: 2rem;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 992px) {
    #footer .component.rich-text-list .component-content p.text-container:after {
      display: none; } }

#footer .component.image {
  display: none; }
  @media only screen and (min-width: 992px) {
    #footer .component.image {
      display: block;
      position: absolute;
      bottom: 0; }
      #footer .component.image img {
        max-width: 200px;
        height: auto; } }

#footer ul > li ul > li {
  list-style: none; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.breadcrumb .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.breadcrumb .component-content ol {
    list-style: none; }
    .component.breadcrumb .component-content ol li {
      text-indent: 0;
      padding-left: 0; }
    .component.breadcrumb .component-content ol li, .component.breadcrumb .component-content ol div {
      display: inline-block;
      white-space: nowrap; }
      .component.breadcrumb .component-content ol li:last-child .separator, .component.breadcrumb .component-content ol div:last-child .separator {
        display: none; }

.breadcrumb a, .breadcrumb .separator {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #00558C;
  text-decoration: none; }
  @media all and (-ms-high-contrast: none) {
    .breadcrumb a, .breadcrumb .separator {
      font-weight: bold; } }

.component.data-hierarchy-spot.ui-elements {
  position: relative; }
  .component.data-hierarchy-spot.ui-elements .component-content {
    position: relative;
    width: 77rem;
    max-width: 80%;
    margin: 0 auto; }
  .component.data-hierarchy-spot.ui-elements .section-navigation {
    position: absolute;
    left: -2rem;
    transform: translateX(-100%);
    text-align: right; }
    .component.data-hierarchy-spot.ui-elements .section-navigation.sticky {
      position: fixed;
      top: 11rem;
      left: 2rem;
      text-align: left;
      transform: none; }
    .component.data-hierarchy-spot.ui-elements .section-navigation .navigation {
      margin-bottom: 0.5rem;
      font-size: 0.8rem; }
      .component.data-hierarchy-spot.ui-elements .section-navigation .navigation a.active {
        font-family: "Open Sans", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal; }
        @media all and (-ms-high-contrast: none) {
          .component.data-hierarchy-spot.ui-elements .section-navigation .navigation a.active {
            font-weight: bold; } }
  .component.data-hierarchy-spot.ui-elements .section {
    clear: both; }
  .component.data-hierarchy-spot.ui-elements .header {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.333rem;
    line-height: 1.3;
    color: #0e1111;
    margin-bottom: 1rem; }
    @media all and (-ms-high-contrast: none) {
      .component.data-hierarchy-spot.ui-elements .header {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .component.data-hierarchy-spot.ui-elements .header {
        font-size: 1.25rem; } }
    .component.data-hierarchy-spot.ui-elements .header .header__underline {
      height: 1px;
      width: 10rem;
      background-color: #D7D2CB;
      margin-top: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .color {
    display: block;
    float: left;
    position: relative;
    width: 10rem;
    height: 10rem;
    padding: 0.5rem;
    margin: 0 2rem 2rem 0; }
    .component.data-hierarchy-spot.ui-elements .color.white-color-border {
      border: thin solid #BBB; }
    .component.data-hierarchy-spot.ui-elements .color:nth-child(3n+3) {
      clear: both; }
    .component.data-hierarchy-spot.ui-elements .color .color__name {
      position: absolute;
      bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .color .color__hexcode {
      position: absolute;
      bottom: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .type {
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .type .type__description {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 0.8rem;
      margin-top: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .font {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .font.light {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.regular {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.italic {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.medium {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 600;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.bold {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal; }
      @media all and (-ms-high-contrast: none) {
        .component.data-hierarchy-spot.ui-elements .font.bold {
          font-weight: bold; } }
  .component.data-hierarchy-spot.ui-elements .button-element {
    margin: 0 2rem 2rem 0;
    display: inline-block; }
  .component.data-hierarchy-spot.ui-elements .form-element {
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .form-element .form-element__description {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 0.8rem;
      margin-top: 0.5rem; }

#wrapper .component.iframe:not(.full-width):not(.slim) .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

#wrapper .component.iframe iframe {
  width: 100%; }

.component.intro-banner {
  padding-top: 0;
  padding-bottom: 0; }
  .component.intro-banner .component-content {
    position: relative; }
  .component.intro-banner .intro-banner-image {
    width: 100%; }
    .component.intro-banner .intro-banner-image img {
      max-width: 100%;
      height: auto;
      display: block;
      width: 100%; }
  .component.intro-banner .intro-banner-content {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    right: 0; }
  .component.intro-banner .outer-pos-wrapper {
    position: relative;
    width: 95rem;
    max-width: 80%;
    margin: 0 auto;
    height: 100%; }
  .component.intro-banner .inner-pos-wrapper {
    width: 100%;
    position: relative;
    display: inline-block; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner .inner-pos-wrapper {
        width: 55%; } }
  .component.intro-banner .intro-banner-link {
    display: none; }
  .component.intro-banner .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    .component.intro-banner .sticky-downlink a {
      display: inline-block;
      transition: all 0.2s;
      color: inherit; }
      .component.intro-banner .sticky-downlink a:before {
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 4rem;
        line-height: 1;
        color: inherit; }
      .component.intro-banner .sticky-downlink a:hover {
        margin-bottom: -.5rem; }
      .component.intro-banner .sticky-downlink a span {
        display: none; }
  .component.intro-banner.middle .inner-pos-wrapper {
    top: 50%;
    transform: translateY(-50%); }
  .component.intro-banner.bottom .inner-pos-wrapper {
    top: 100%;
    transform: translateY(-100%); }
  .component.intro-banner.right .outer-pos-wrapper {
    text-align: right; }
  .component.intro-banner.center .outer-pos-wrapper {
    text-align: center; }
  .component.intro-banner.content-overflow .intro-banner-content {
    position: static;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-bottom: 1rem; } }
  .component.intro-banner.content-overflow .inner-pos-wrapper {
    position: static;
    transform: none; }

.component.rich-text-list .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.rich-text-list .component-content:before, .component.rich-text-list .component-content:after {
    content: '';
    clear: both;
    display: table; }
  .component.rich-text-list .component-content > ul {
    list-style: none; }
    .component.rich-text-list .component-content > ul > li {
      width: 100%;
      text-indent: 0;
      padding-left: 0; }
      .component.rich-text-list .component-content > ul > li + li {
        margin-top: 2rem; }
      .component.rich-text-list .component-content > ul > li ul {
        list-style: none; }
        .component.rich-text-list .component-content > ul > li ul li + li {
          margin-top: .7rem; }
        .component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
          padding-bottom: .7rem;
          border-bottom: 1px solid rgba(75, 79, 84, 0.5); }
      .component.rich-text-list .component-content > ul > li:not(:last-child) {
        padding-right: 2rem; }

@media only screen and (min-width: 992px) {
  .component.rich-text-list .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .component.rich-text-list .component-content > ul > li + li {
      margin-top: 0; } }

.component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
  border-bottom: 0; }

.component.video-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.video-spot .component-content code {
    display: none; }
  .component.video-spot .component-content .video-spot-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    cursor: pointer; }
  .component.video-spot .component-content .video-spot-container,
  .component.video-spot .component-content .video-spot-video,
  .component.video-spot .component-content .video-spot-image,
  .component.video-spot .component-content img {
    display: inline-block; }
  .component.video-spot .component-content .video-spot-play-btn {
    display: none; }
  .component.video-spot .component-content .video-spot-image img {
    max-width: 100%;
    height: auto; }

.component.video-spot.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 80%;
  margin: 0 auto; }

.video-spot-overlay {
  display: none;
  background-color: rgba(173, 220, 145, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  .video-spot-overlay .video-spot-overlay-close {
    width: 3rem;
    height: 3rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer; }
    .video-spot-overlay .video-spot-overlay-close::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      font-family: "DGS-WebProgram";
      speak: none;
      font-size: 3rem;
      color: #ADDC91; }
  .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.video-spot-overlay {
  background: rgba(14, 17, 17, 0.7); }
  .video-spot-overlay .video-spot-overlay-close {
    top: 10rem; }
  .video-spot-overlay.error-msg-on .video-spot-error-msg {
    width: 100%;
    padding: 1rem;
    display: block;
    background: #ffffff;
    border: 2px solid #ADDC91;
    text-align: center;
    border-radius: 5px;
    box-sizing: border-box; }
    @media only screen and (min-width: 768px) {
      .video-spot-overlay.error-msg-on .video-spot-error-msg {
        width: 600px;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    .video-spot-overlay.error-msg-on .video-spot-error-msg .video-spot-error-msg-header {
      margin: 0.5rem 0 1rem; }
    .video-spot-overlay.error-msg-on .video-spot-error-msg .video-spot-error-msg-desc {
      margin: 0; }
    .video-spot-overlay.error-msg-on .video-spot-error-msg #video-spot-error-msg-btn {
      display: inline-block;
      border-color: #ffffff;
      border-width: 3px;
      border-style: solid;
      padding: 0.9375rem 2rem;
      text-decoration: none;
      background-color: #ADDC91;
      text-align: center;
      transition: all 0.2s;
      -webkit-appearance: none;
      color: #0e1111;
      font-weight: 700;
      font-size: 1.33rem;
      border: 0;
      font-size: 1rem;
      margin: 1.5rem 0 0.5rem; }
      .video-spot-overlay.error-msg-on .video-spot-error-msg #video-spot-error-msg-btn:not(.disabled) {
        cursor: pointer; }
      .video-spot-overlay.error-msg-on .video-spot-error-msg #video-spot-error-msg-btn:not(.disabled):hover {
        background-color: #ADDC91;
        color: #0e1111; }
        .video-spot-overlay.error-msg-on .video-spot-error-msg #video-spot-error-msg-btn:not(.disabled):hover a {
          text-decoration: none;
          color: #0e1111;
          text-shadow: none; }
      .video-spot-overlay.error-msg-on .video-spot-error-msg #video-spot-error-msg-btn a {
        color: #0e1111;
        text-decoration: none;
        background-color: transparent; }
  .video-spot-overlay.error-msg-on.no-marketing-cookie .component-content .video-spot-error-msg {
    display: block; }
  .video-spot-overlay.error-msg-on .video-spot-overlay-close {
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem; }
    .video-spot-overlay.error-msg-on .video-spot-overlay-close::after {
      font-size: 1rem;
      font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .video-spot-overlay.error-msg-on .video-spot-overlay-close {
        top: 6rem;
        right: 3rem;
        width: 3rem;
        height: 3rem; }
        .video-spot-overlay.error-msg-on .video-spot-overlay-close::after {
          font-size: 3rem; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.rich-content-spot {
  margin-top: 10rem; }
  .component.rich-content-spot .component-content {
    position: relative;
    width: 95rem;
    max-width: 80%;
    margin: 0 auto; }
    .component.rich-content-spot .component-content h2 {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.333rem;
      line-height: 1.3;
      color: #0e1111;
      color: #6BCABA; }
      @media all and (-ms-high-contrast: none) {
        .component.rich-content-spot .component-content h2 {
          font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        .component.rich-content-spot .component-content h2 {
          font-size: 1.25rem; } }

.component.rich-content-spot + .component.rich-text h3 {
  color: #6BCABA; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.image-spot.slim .component-content, .component.image-spot .component-content, .component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.image-spot.slim .component-content {
  width: 77rem; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 15px !important; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.image-spot .component-content .image-spot-elements .image-spot-element-text, .component.image-spot .component-content .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

.component.image-spot .component-content ul {
  list-style: none; }

.component.image-spot .component-content li {
  margin-top: 0; }

.component.image-spot .component-content .image-spot-elements {
  text-align: center;
  position: relative; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element {
    list-style: none;
    margin-bottom: 2rem;
    overflow: hidden;
    text-indent: 0;
    padding-left: 0; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element-image {
    padding: 0 25%; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element-image:not(:empty) {
      margin-bottom: 1rem; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element-image * {
      max-width: 100%;
      height: auto; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
    margin-top: 1rem; }

@media only screen and (min-width: 992px) {
  .component.image-spot .component-content .image-spot-elements {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */
    /* two items */
    /* three items */
    /* four items */
    /* five items */ }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
      padding: 0 4rem; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
      padding: 0 2rem; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
      padding: 0 1.5rem; }
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
      padding: 0 1rem; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; }
  .component.image-spot .component-content .image-spot-element-context {
    display: none; } }

.component.image-spot.round-spot .image-spot-element-image img {
  display: inline-block;
  border-radius: 50%; }

.component.image-spot.slim .component-content, .component.image-spot .component-content, .component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.image-spot.slim .component-content {
  width: 77rem; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 15px !important; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.image-spot:not(.round-spot) .image-spot-element-image-description, .component.image-spot:not(.round-spot) .image-spot-element-text {
  text-align: left;
  float: left;
  width: 100%;
  margin-top: 0.75rem; }

.component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element-link {
  float: right;
  padding: 0.9375rem 1rem; }
  .component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
    margin-top: 2.25rem; }

.component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element-text {
  max-width: none; }

.component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element {
  padding: 0 0.6875rem; }
  .component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element .image-spot-element-image-description {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.333rem;
    margin-top: 8px;
    display: block; }
    @media all and (-ms-high-contrast: none) {
      .component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element .image-spot-element-image-description {
        font-weight: bold; } }
  .component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element .image-spot-element-context .image-spot-element-context-content {
    float: left; }
    .component.image-spot:not(.round-spot) .component-content .image-spot-elements .image-spot-element .image-spot-element-context .image-spot-element-context-content:not(:empty) {
      margin-top: 0.75rem; }

.component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1),
.component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1) ~ li {
  width: 25%;
  float: none;
  display: inline-block;
  vertical-align: top; }
  .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1) .image-spot-element-image,
  .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1) ~ li .image-spot-element-image {
    padding: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1) ~ li {
      width: calc(50% - 0.6875rem); } }
  @media only screen and (max-width: 767px) {
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1),
    .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(n+1) ~ li {
      width: calc(50% - 0.6875rem); } }

.component.image-spot .component-content .image-spot-elements .image-spot-element {
  padding: 0 1.46875rem; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
    display: inline-block;
    border-color: #ffffff;
    border-width: 3px;
    border-style: solid;
    padding: 0.9375rem 2rem;
    text-decoration: none;
    background-color: #ADDC91;
    text-align: center;
    transition: all 0.2s;
    -webkit-appearance: none;
    color: #0e1111;
    font-weight: 700;
    font-size: 1.33rem;
    padding: 0.9375rem 2rem;
    display: inline-block; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link:not(.disabled) {
      cursor: pointer; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link:not(.disabled):hover {
      background-color: #ADDC91;
      color: #0e1111; }
      .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link:not(.disabled):hover a {
        text-decoration: none;
        color: #0e1111;
        text-shadow: none; }
    .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
      color: #0e1111;
      text-decoration: none;
      background-color: transparent; }
    @media only screen and (max-width: 568px) {
      .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
        padding: 0.7375rem 0.882rem; } }
    .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1rem; }
      @media all and (-ms-high-contrast: none) {
        .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
          font-weight: bold; } }
  .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    display: none; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-image-description {
    display: none; }
  .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    max-width: 100%;
    margin-top: 0.75rem; }
    @media all and (-ms-high-contrast: none) {
      .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
        font-weight: bold; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.image-spot.slim .component-content, .component.image-spot .component-content, .component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.image-spot.slim .component-content {
  width: 77rem; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 15px !important; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

.component.rich-text.two-column-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto;
  column-count: 2;
  column-gap: 48px; }
  @media only screen and (max-width: 767px) {
    .component.rich-text.two-column-text .component-content {
      column-count: 1;
      padding-top: 12px; } }
  .component.rich-text.two-column-text .component-content ul li {
    position: relative;
    list-style: none;
    padding-left: 20px;
    text-indent: 0; }
    .component.rich-text.two-column-text .component-content ul li:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 8px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #0e1111; }

.component.image-spot.slim .component-content, .component.image-spot .component-content, .component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.image-spot.slim .component-content {
  width: 77rem; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 11px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 15px !important; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.component.text-image-spot .text-image-spot-image-container {
  background-size: 0px; }

.component.text-image-spot .text-image-spot-image img {
  max-width: 100%;
  height: auto; }

.component.text-image-spot .text-image-spot-text-container {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }

.component.text-image-spot.inoborder {
  padding-top: 0;
  padding-bottom: 0; }
  .component.text-image-spot.inoborder .text-image-spot-text-container {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-top: 1rem; } }
    .component.text-image-spot.inoborder .text-image-spot-text-container.repeating-background {
      padding-top: 0; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-bottom: 1rem; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

.component.text-image-spot.multiple-images {
  margin-top: 2rem;
  position: relative; }
  .component.text-image-spot.multiple-images.iright .text-image-spot-image-elements {
    margin-top: 1rem; }
  .component.text-image-spot.multiple-images .text-image-spot-image-container:after {
    content: "";
    display: table;
    clear: both; }

.component.text-image-spot .text-image-spot-image-elements {
  max-width: 80%;
  margin: 0 auto; }
  .component.text-image-spot .text-image-spot-image-elements:after {
    content: "";
    display: table;
    clear: both; }
  .component.text-image-spot .text-image-spot-image-elements.slick-slider .text-image-spot-element {
    max-width: none; }

.component.text-image-spot .text-image-spot-element {
  display: block;
  float: right;
  padding: 0;
  max-width: 25%; }
  .component.text-image-spot .text-image-spot-element:before {
    display: none; }

.component.text-image-spot .text-image-spot-image-element {
  padding-left: 1rem; }
  .component.text-image-spot .text-image-spot-image-element img {
    max-width: 100%;
    height: auto;
    border-bottom: 3px solid transparent; }
  .component.text-image-spot .text-image-spot-image-element img:hover, .component.text-image-spot .text-image-spot-image-element img.selected {
    border-bottom: 3px solid #A9A9A9; }

.component.text-image-spot .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
  border: solid 3px #A9A9A9;
  border-radius: 20px; }
  .component.text-image-spot .slick-arrow:before {
    position: relative;
    top: 1px;
    font-family: "DGS-WebProgram";
    font-size: 20px;
    line-height: 1;
    color: #A9A9A9; }
  .component.text-image-spot .slick-arrow.slick-next {
    right: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-next:before {
      content: "";
      left: 1px; }
  .component.text-image-spot .slick-arrow.slick-prev {
    left: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-prev:before {
      content: "";
      right: 1px; }
  .component.text-image-spot .slick-arrow.slick-disabled {
    cursor: default;
    opacity: 0.5; }

@media only screen and (min-width: 992px) {
  .component.text-image-spot {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .component.text-image-spot .component-content {
      display: table-row;
      width: 100%; }
    .component.text-image-spot .text-image-spot-image-container,
    .component.text-image-spot .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    .component.text-image-spot .text-image-spot-text {
      position: relative; }
    .component.text-image-spot:not(.iright) .text-image-spot-text {
      float: left;
      padding-left: 2rem; } }
    @media only screen and (min-width: 992px) and (max-width: 767px) {
      .component.text-image-spot:not(.iright) .text-image-spot-text {
        padding-left: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot.iright {
      direction: rtl; }
      .component.text-image-spot.iright .text-image-spot-image-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text {
        float: right;
        padding-right: 2rem; } }
      @media only screen and (min-width: 992px) and (max-width: 767px) {
        .component.text-image-spot.iright .text-image-spot-text {
          padding-right: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-text {
      box-sizing: border-box;
      width: 47.5rem;
      max-width: 80%; }
    .component.text-image-spot.tslim .text-image-spot-text {
      width: 38.5rem; }
    .component.text-image-spot.tw40 .text-image-spot-text {
      position: relative;
      width: 38rem;
      max-width: 64%;
      left: 9.5rem; }
    .component.text-image-spot.tw40.iright .text-image-spot-text {
      left: -9.5rem; }
    .component.text-image-spot.tw40.tslim .text-image-spot-text {
      width: 30.8rem;
      left: 7.7rem; }
    .component.text-image-spot.tw60 .text-image-spot-text {
      position: relative;
      width: 57rem;
      max-width: 96%;
      left: -9.5rem; }
    .component.text-image-spot.tw60.tslim .text-image-spot-text {
      width: 46.2rem;
      left: -7.7rem; }
    .component.text-image-spot .text-image-spot-text-container {
      vertical-align: top; }
    .component.text-image-spot.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    .component.text-image-spot.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    .component.text-image-spot .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      .component.text-image-spot .text-image-spot-image img {
        float: right;
        max-width: 100%;
        height: auto; }
    .component.text-image-spot.inormal .text-image-spot-image img {
      width: 47.5rem;
      max-width: 80%; }
    .component.text-image-spot.islim .text-image-spot-image img {
      width: 38.5rem;
      max-width: 80%; }
    .component.text-image-spot.iright .text-image-spot-image img {
      float: left; }
    .component.text-image-spot.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    .component.text-image-spot.iw40 .text-image-spot-image {
      width: 80%; }
    .component.text-image-spot.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    .component.text-image-spot.iw40.inormal .text-image-spot-image {
      width: 38rem; }
    .component.text-image-spot.iw40.inormal.iright .text-image-spot-image {
      margin-left: 9.5rem; }
    .component.text-image-spot.iw40.islim .text-image-spot-image {
      width: 30.8rem; }
    .component.text-image-spot.iw40.islim.iright .text-image-spot-image {
      margin-left: 7.7rem; }
    .component.text-image-spot.iw60 .text-image-spot-image {
      width: 120%; }
    .component.text-image-spot.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    .component.text-image-spot.iw60.inormal .text-image-spot-image {
      width: 57rem; }
    .component.text-image-spot.iw60.inormal.iright .text-image-spot-image {
      margin-left: -9.5rem; }
    .component.text-image-spot.iw60.islim .text-image-spot-image {
      width: 46.2rem; }
    .component.text-image-spot.iw60.islim.iright .text-image-spot-image {
      margin-left: -7.7rem; }
    .component.text-image-spot .text-image-spot-image-container {
      vertical-align: top; }
    .component.text-image-spot.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    .component.text-image-spot.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    .component.text-image-spot.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    .component.text-image-spot.tforceheight .text-image-spot-image img {
      display: none; }
    .component.text-image-spot.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -9.5rem; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 9.5rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -7.7rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 7.7rem; }
  .multiple-images.iright .text-image-spot-image-elements {
    float: left; }
  .multiple-images .text-image-spot-image-elements {
    max-width: 90%;
    float: right; }
  .multiple-images .slick-arrow.slick-next {
    right: -40px; }
  .multiple-images .slick-arrow.slick-prev {
    left: -40px; } }

.round-image img {
  border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .round-image .component-content .text-image-spot-text-container .text-image-spot-text {
    max-width: 60%; } }

.component.text-image-spot a.call-to-action, .component.text-image-spot a[class*="like-button"] {
  float: right; }

.component.text-image-spot .component-content {
  padding-bottom: 30px; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot .component-content {
      padding-bottom: 0; } }
  .component.text-image-spot .component-content .text-image-spot-text-container {
    padding-right: 0; }
    @media only screen and (min-width: 992px) {
      .component.text-image-spot .component-content .text-image-spot-text-container {
        padding-right: 50px; } }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot .component-content .text-image-spot-image {
      max-width: 100%;
      margin: 0; } }
  .component.text-image-spot .component-content .text-image-spot-image img {
    margin: 0 auto;
    padding: 35px 0;
    max-width: 50%;
    display: block; }
    @media only screen and (min-width: 992px) {
      .component.text-image-spot .component-content .text-image-spot-image img {
        max-width: 100%;
        margin: 35px 0;
        padding: 0; } }

.component.blue-dotted .component-content {
  background: url(/design/build/williamdemantfoundation/assets/WDF_stardot-master_skyblue.png) no-repeat; }

.component.green-dotted .component-content {
  background: url(/design/build/williamdemantfoundation/assets/WDF_stardot-master_grass-green.png) no-repeat; }

#wrapper .component.blog .blog-image img {
  width: 100%;
  display: block; }

#wrapper .component.blog .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 2rem; }
  #wrapper .component.blog .component-content .blog-reading-time,
  #wrapper .component.blog .component-content .blog-author {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-date {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-author-value {
    color: inherit;
    font-family: inherit; }
  #wrapper .component.blog .component-content .blog-title {
    margin: 1rem 0 0.2rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 700; }
  #wrapper .component.blog .component-content .blog-content {
    margin-bottom: 0.8rem; }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog .component-content .blog-title {
    margin: 2rem 0; } }

.component.blog-author {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.blog-author .component-content {
    width: 100%;
    margin: 0 0 0.9rem auto; }
    @media only screen and (min-width: 992px) {
      .component.blog-author .component-content {
        width: 75%; } }
    .component.blog-author .component-content .blog-author-name,
    .component.blog-author .component-content .blog-author-link {
      display: none; }
    #content .component.blog-author .component-content h2.blog-author-about {
      margin: 0;
      font-size: 100%;
      font-weight: bold;
      float: left;
      width: 65%; }
      @media only screen and (min-width: 992px) {
        #content .component.blog-author .component-content h2.blog-author-about {
          width: 60%; } }
    .component.blog-author .component-content::after {
      clear: both;
      content: "";
      display: block; }
    .component.blog-author .component-content .blog-author-image img {
      width: 100px;
      height: auto;
      float: left;
      padding-right: 0.9rem;
      padding-bottom: 0.9rem; }
      @media only screen and (min-width: 992px) {
        .component.blog-author .component-content .blog-author-image img {
          width: 200px; } }
    .component.blog-author .component-content .blog-author-intro {
      min-height: 100px;
      line-height: 1.5;
      float: left;
      width: 65%; }
      @media only screen and (min-width: 992px) {
        .component.blog-author .component-content .blog-author-intro {
          width: 60%; } }
    .component.blog-author .component-content .blog-author-title {
      line-height: 1.5;
      float: left;
      width: 65%; }
      @media only screen and (min-width: 992px) {
        .component.blog-author .component-content .blog-author-title {
          width: 60%; } }

#content .component.author-related-blogs {
  background-color: #333;
  position: relative; }
  #content .component.author-related-blogs .related-blogs-title {
    display: none; }
  #content .component.author-related-blogs .author-header {
    line-height: 1.8rem;
    font-size: 1.5rem;
    position: relative;
    display: inline-block;
    width: auto;
    margin: 2rem 2rem 0; }
    #content .component.author-related-blogs .author-header .author-name {
      font-weight: bold; }
  #content .component.author-related-blogs ul {
    padding-bottom: 1rem; }
    #content .component.author-related-blogs ul li {
      box-sizing: border-box;
      padding: 0;
      background-color: #ffffff;
      text-align: center; }
      #content .component.author-related-blogs ul li > div {
        height: 100%;
        overflow: hidden; }
    #content .component.author-related-blogs ul.slick-slider .slick-track > div {
      margin: 1rem 0.5rem; }
    #content .component.author-related-blogs ul.slick-slider .slick-arrow {
      position: absolute;
      top: 50%;
      font-size: 0;
      width: 2rem;
      height: 2rem;
      margin-top: -1rem;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent; }
      #content .component.author-related-blogs ul.slick-slider .slick-arrow::before {
        font-weight: bold;
        opacity: .75;
        font-size: 2rem;
        line-height: 1;
        font-family: "Oticon-icon-www";
        color: #333; }
      #content .component.author-related-blogs ul.slick-slider .slick-arrow:hover::before {
        opacity: 1; }
      #content .component.author-related-blogs ul.slick-slider .slick-arrow.slick-prev {
        left: -3rem; }
        #content .component.author-related-blogs ul.slick-slider .slick-arrow.slick-prev::before {
          content: ""; }
      #content .component.author-related-blogs ul.slick-slider .slick-arrow.slick-next {
        right: -3rem; }
        #content .component.author-related-blogs ul.slick-slider .slick-arrow.slick-next::before {
          content: ""; }
    #content .component.author-related-blogs ul.slick-slider .slick-dots {
      text-align: center; }
      #content .component.author-related-blogs ul.slick-slider .slick-dots > li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        background-color: transparent; }
        #content .component.author-related-blogs ul.slick-slider .slick-dots > li > button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 1rem;
          height: 1rem;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent; }
          #content .component.author-related-blogs ul.slick-slider .slick-dots > li > button::before {
            content: '•';
            background-color: transparent;
            font-size: 3rem;
            line-height: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .5;
            color: #333;
            transition: transform 0.3s, opacity 0.3s; }
        #content .component.author-related-blogs ul.slick-slider .slick-dots > li.slick-active > button::before {
          opacity: 1;
          margin-top: -1px;
          transform: scale(1.4); }
  #content .component.author-related-blogs .author-blog-image {
    height: 60%;
    overflow: hidden;
    margin-bottom: .5rem; }
    #content .component.author-related-blogs .author-blog-image img {
      min-height: 100%;
      margin: 0 auto; }
  #content .component.author-related-blogs .author-blog-text {
    padding: 0 1rem;
    line-height: 1.7rem;
    height: 5rem;
    overflow: hidden;
    font-size: 1.2rem; }
  #content .component.author-related-blogs .author-blog-link {
    margin: 1rem; }
    #content .component.author-related-blogs .author-blog-link a.link {
      font-size: 1.5rem;
      margin: 0;
      padding-left: 0; }
      #content .component.author-related-blogs .author-blog-link a.link:hover {
        padding-left: 1rem; }
    #content .component.author-related-blogs .author-blog-link a::before {
      content: ''; }

@media only screen and (min-width: 768px) {
  #content .component.author-related-blogs .author-header {
    position: absolute;
    left: 3.5%;
    top: 2rem;
    width: 10%;
    margin: 0; }
  #content .component.author-related-blogs .author-blog-image {
    height: 52%; }
  #content .component.author-related-blogs ul {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    justify-content: center; }
    #content .component.author-related-blogs ul li {
      height: 23rem; }
      #content .component.author-related-blogs ul li .author-blog-text {
        padding: 0 1rem;
        line-height: 1.5rem;
        height: 6rem;
        /* height is 4x line-height, so four lines will display */ }
      #content .component.author-related-blogs ul li .author-blog-link {
        margin-top: 1rem; }
        #content .component.author-related-blogs ul li .author-blog-link a.link {
          font-size: 1.3rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #content .component.author-related-blogs .author-header {
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 0 1.5rem 1.5rem; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #content .component.author-related-blogs .author-header {
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 2em 1em; } }

.component.blog-categories {
  padding-top: 0;
  padding-bottom: 0;
  position: relative; }
  .component.blog-categories .component-content .blog-categories-content {
    background: #333;
    margin: 0; }
    .component.blog-categories .component-content .blog-categories-content .blog-category {
      border: 0;
      border-bottom: 1px solid #eee; }
      .component.blog-categories .component-content .blog-categories-content .blog-category a {
        color: #eee;
        display: block;
        max-width: none;
        padding: 0.8rem 1.2rem;
        width: auto;
        text-align: center; }
        .component.blog-categories .component-content .blog-categories-content .blog-category a::after {
          position: relative;
          content: '';
          display: block;
          height: 1px;
          width: 0;
          top: 0.3rem;
          left: 0;
          right: 0;
          margin: auto;
          background-color: white;
          transition: width, 0.3s; }
        .component.blog-categories .component-content .blog-categories-content .blog-category a.active {
          font-weight: 700; }
          .component.blog-categories .component-content .blog-categories-content .blog-category a.active::after {
            width: 5rem; }

@media only screen and (min-width: 992px) {
  .component.blog-categories .component-content .blog-categories-content-wrapper {
    position: relative;
    width: 95rem;
    max-width: 80%;
    margin: 0 auto;
    display: flex; }
    .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category {
      border: 0;
      border-right: 1px solid #eee; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category a {
        display: inline-block;
        padding: 1.25rem 0.95rem;
        text-align: center;
        min-width: 7.8rem;
        transition: transform 0.1s; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:last-child {
        border-right: 0; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover a {
        transform: scale(1.1); }
  .component.blog-categories.add-opacity .blog-categories-content {
    background-color: rgba(51, 51, 51, 0.8); } }

.component.blog-list #blog-list li,
.component.latest-blog-posts #blog-list li {
  list-style: none;
  text-indent: 0;
  padding: 0;
  margin: 1rem; }
  .component.blog-list #blog-list li > a,
  .component.latest-blog-posts #blog-list li > a {
    display: block; }
  .component.blog-list #blog-list li .news-title,
  .component.latest-blog-posts #blog-list li .news-title {
    display: none; }
  .component.blog-list #blog-list li .news-text,
  .component.blog-list #blog-list li .news-link,
  .component.latest-blog-posts #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-link {
    margin-top: 1rem;
    text-align: center; }
  .component.blog-list #blog-list li .news-image,
  .component.latest-blog-posts #blog-list li .news-image {
    text-align: center; }
    .component.blog-list #blog-list li .news-image img,
    .component.latest-blog-posts #blog-list li .news-image img {
      max-width: 100%; }
  .component.blog-list #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-text {
    line-height: 1.5rem; }
  .component.blog-list #blog-list li .news-link span,
  .component.latest-blog-posts #blog-list li .news-link span {
    margin: 0; }
  .component.blog-list #blog-list li .news-date,
  .component.latest-blog-posts #blog-list li .news-date {
    text-align: center;
    padding: 0.5rem 0;
    color: #333; }

@media only screen and (min-width: 768px) {
  .component.blog-list #blog-list,
  .component.latest-blog-posts #blog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3.6rem 0; }
    .component.blog-list #blog-list li,
    .component.latest-blog-posts #blog-list li {
      box-sizing: border-box;
      margin: 0 auto 1rem auto;
      padding: 0 2%;
      width: 33%; }
      .component.blog-list #blog-list li .news-image img,
      .component.latest-blog-posts #blog-list li .news-image img {
        max-height: 280px; }
      .component.blog-list #blog-list li .news-text,
      .component.latest-blog-posts #blog-list li .news-text {
        height: 6rem;
        max-height: 6rem;
        overflow: hidden; } }

@media only screen and (min-width: 992px) {
  .component.blog-list .component-content,
  .component.latest-blog-posts .component-content {
    position: relative;
    width: 95rem;
    max-width: 80%;
    margin: 0 auto; } }

#wrapper .component.related-blogs {
  clear: both;
  background-color: transparent; }
  #wrapper .component.related-blogs .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 2rem; }
    #wrapper .component.related-blogs .component-content .related-title {
      display: none; }
    #wrapper .component.related-blogs .component-content ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
      #wrapper .component.related-blogs .component-content ul li {
        margin: 1rem 0;
        width: 100%;
        padding: 0 1%;
        box-sizing: border-box;
        list-style: none; }
        #wrapper .component.related-blogs .component-content ul li:nth-child(n + 4) {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-image {
          text-align: center; }
          #wrapper .component.related-blogs .component-content ul li .news-image img {
            max-width: 100%;
            height: auto; }
        #wrapper .component.related-blogs .component-content ul li .news-text,
        #wrapper .component.related-blogs .component-content ul li .news-link {
          background: transparent;
          font-size: 1.4rem;
          padding: 0.6rem 0.6rem 0;
          text-align: center; }
        #wrapper .component.related-blogs .component-content ul li .news-date {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-text {
          max-height: 6rem;
          height: 6rem;
          overflow: hidden;
          line-height: 1.4; }
        #wrapper .component.related-blogs .component-content ul li .news-link {
          padding-bottom: 10px; }
          #wrapper .component.related-blogs .component-content ul li .news-link a.link {
            margin: 0;
            padding-left: 0; }
            #wrapper .component.related-blogs .component-content ul li .news-link a.link:hover {
              padding-left: 0; }
          #wrapper .component.related-blogs .component-content ul li .news-link a::before {
            content: ""; }

@media only screen and (min-width: 768px) {
  #wrapper .component.related-blogs .component-content ul li {
    width: 33%;
    padding: 0 2%; }
    #wrapper .component.related-blogs .component-content ul li .news-text,
    #wrapper .component.related-blogs .component-content ul li .news-link {
      font-size: 1.1rem; }
    #wrapper .component.related-blogs .component-content ul li .news-image img {
      max-height: 150px;
      max-width: 100%; } }

#wrapper .comments .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  #wrapper .comments .component-content .topCommentsRow {
    margin-bottom: 1rem; }
  #wrapper .comments .component-content .totalComments {
    display: inline-block;
    margin-left: 1rem; }

#wrapper .comments .comments-summary {
  background: #d3d3d3 !important;
  margin: 1rem 0;
  padding: 1rem; }
  #wrapper .comments .comments-summary li {
    list-style: none;
    padding-bottom: 0.5rem; }
    #wrapper .comments .comments-summary li:last-child {
      padding-bottom: 0; }

#wrapper .comments .anonymous-comment-form .comment-form-fields {
  margin-top: 1rem; }
  @media only screen and (min-width: 992px) {
    #wrapper .comments .anonymous-comment-form .comment-form-fields {
      width: 50%; } }
  #wrapper .comments .anonymous-comment-form .comment-form-fields > div {
    margin-bottom: 1.3rem; }
  #wrapper .comments .anonymous-comment-form .comment-form-fields input,
  #wrapper .comments .anonymous-comment-form .comment-form-fields textarea {
    width: 100%; }
  #wrapper .comments .anonymous-comment-form .comment-form-fields .validation-message {
    display: block;
    padding-bottom: 1rem; }

#wrapper .comments .comments-list-content {
  padding-top: 1rem; }
  #wrapper .comments .comments-list-content > ul {
    list-style: none; }
    #wrapper .comments .comments-list-content > ul > li {
      text-indent: 0;
      padding-left: 0; }
  #wrapper .comments .comments-list-content .commentsItem,
  #wrapper .comments .comments-list-content .replyItem {
    margin-bottom: 1rem; }
    #wrapper .comments .comments-list-content .commentsItem > br,
    #wrapper .comments .comments-list-content .replyItem > br {
      display: none; }
  #wrapper .comments .comments-list-content .userNameText {
    font-weight: bold; }
  #wrapper .comments .comments-list-content .replyItem {
    margin-left: 1.8rem; }

#wrapper .component.blog .component-content .blog-content {
  margin-bottom: 2rem; }
  #wrapper .component.blog .component-content .blog-content .button-container {
    column-count: unset;
    margin-top: 3rem; }
    #wrapper .component.blog .component-content .blog-content .button-container:after {
      content: "";
      clear: both;
      display: table; }
    #wrapper .component.blog .component-content .blog-content .button-container .like-button {
      display: inline-block;
      border-color: #ffffff;
      border-width: 3px;
      border-style: solid;
      padding: 0.9375rem 2rem;
      text-decoration: none;
      background-color: #4EC3E0;
      text-align: center;
      transition: all 0.2s;
      -webkit-appearance: none;
      color: #ffffff;
      font-weight: 700;
      font-size: 1.33rem;
      float: right;
      padding: 0.9375rem 2rem; }
      #wrapper .component.blog .component-content .blog-content .button-container .like-button:not(.disabled) {
        cursor: pointer; }
      #wrapper .component.blog .component-content .blog-content .button-container .like-button:not(.disabled):hover {
        background-color: #4EC3E0;
        color: #ffffff; }
        #wrapper .component.blog .component-content .blog-content .button-container .like-button:not(.disabled):hover a {
          text-decoration: none;
          color: #ffffff;
          text-shadow: none; }
      #wrapper .component.blog .component-content .blog-content .button-container .like-button a {
        color: #ffffff;
        text-decoration: none;
        background-color: transparent; }
      #wrapper .component.blog .component-content .blog-content .button-container .like-button:not(:empty) {
        margin: 0; }
      #wrapper .component.blog .component-content .blog-content .button-container .like-button a {
        font-family: "Open Sans", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal; }
        @media all and (-ms-high-contrast: none) {
          #wrapper .component.blog .component-content .blog-content .button-container .like-button a {
            font-weight: bold; } }
  #wrapper .component.blog .component-content .blog-content p {
    column-count: 2; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.blog .component-content .blog-content p {
        column-count: 1; } }
  #wrapper .component.blog .component-content .blog-content div {
    margin-top: 1rem; }
    #wrapper .component.blog .component-content .blog-content div img:nth-child(odd) {
      padding-right: 3px; }
      @media only screen and (max-width: 767px) {
        #wrapper .component.blog .component-content .blog-content div img:nth-child(odd) {
          padding-right: 0; } }
    #wrapper .component.blog .component-content .blog-content div img:nth-child(even) {
      padding-left: 3px;
      float: right; }
      @media only screen and (max-width: 767px) {
        #wrapper .component.blog .component-content .blog-content div img:nth-child(even) {
          padding-left: 0;
          float: none; } }
  #wrapper .component.blog .component-content .blog-content div img, #wrapper .component.blog .component-content .blog-content p img {
    max-width: 49.9%;
    height: auto !important; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.blog .component-content .blog-content div img, #wrapper .component.blog .component-content .blog-content p img {
        width: 100% !important;
        max-width: 100% !important; } }

#wrapper .component.blog .component-content .blog-title {
  display: none; }

#wrapper .component.blog .component-content .blog-date {
  display: none; }

#wrapper .component.blog-categories .blog-categories-content-wrapper .blog-category {
  display: none; }

#wrapper .component.related-blogs .component-content ul li {
  text-indent: 0;
  width: 50%; }
  @media only screen and (max-width: 767px) {
    #wrapper .component.related-blogs .component-content ul li {
      padding: 0 2%; } }
  #wrapper .component.related-blogs .component-content ul li .news-link {
    display: inline-block;
    border-color: #ffffff;
    border-width: 3px;
    border-style: solid;
    padding: 0.9375rem 2rem;
    text-decoration: none;
    background-color: #ADDC91;
    text-align: center;
    transition: all 0.2s;
    -webkit-appearance: none;
    color: #0e1111;
    font-weight: 700;
    font-size: 1.33rem;
    float: right;
    padding: 0.9375rem 2rem; }
    #wrapper .component.related-blogs .component-content ul li .news-link:not(.disabled) {
      cursor: pointer; }
    #wrapper .component.related-blogs .component-content ul li .news-link:not(.disabled):hover {
      background-color: #ADDC91;
      color: #0e1111; }
      #wrapper .component.related-blogs .component-content ul li .news-link:not(.disabled):hover a {
        text-decoration: none;
        color: #0e1111;
        text-shadow: none; }
    #wrapper .component.related-blogs .component-content ul li .news-link a {
      color: #0e1111;
      text-decoration: none;
      background-color: transparent; }
    #wrapper .component.related-blogs .component-content ul li .news-link:not(:empty) {
      margin: 2.25rem 0 1rem; }
    #wrapper .component.related-blogs .component-content ul li .news-link a {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal; }
      @media all and (-ms-high-contrast: none) {
        #wrapper .component.related-blogs .component-content ul li .news-link a {
          font-weight: bold; } }
  #wrapper .component.related-blogs .component-content ul li .news-text {
    text-align: left;
    color: #0e1111;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-top: 0.75rem;
    padding: 0; }
    @media all and (-ms-high-contrast: none) {
      #wrapper .component.related-blogs .component-content ul li .news-text {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #wrapper .component.related-blogs .component-content ul li .news-text {
        height: 6rem;
        overflow: hidden; } }
  #wrapper .component.related-blogs .component-content ul li .news-date {
    padding: 0 0 0.5rem;
    text-align: left;
    display: block; }
  #wrapper .component.related-blogs .component-content ul li .news-title {
    margin-top: 10px;
    display: block;
    font-size: 1.333rem;
    text-decoration: none;
    color: #0e1111;
    min-height: 40px;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 800;
    font-style: normal;
    border-bottom: none; }
    @media all and (-ms-high-contrast: none) {
      #wrapper .component.related-blogs .component-content ul li .news-title {
        font-weight: bolder; } }
  #wrapper .component.related-blogs .component-content ul li .news-image img {
    max-height: none; }

#wrapper .component.blog-list .component-content .latest-blog-headline, #wrapper .component.latest-blog-posts .component-content .latest-blog-headline {
  display: none; }

@media only screen and (max-width: 767px) {
  #wrapper .component.blog-list .component-content ul#blog-list, #wrapper .component.latest-blog-posts .component-content ul#blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.blog-list .component-content ul#blog-list li, #wrapper .component.latest-blog-posts .component-content ul#blog-list li {
    flex: 0 0 calc(50% - 30px); } }

#wrapper .component.blog-list .component-content ul#blog-list li a, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a {
  text-decoration: none; }
  #wrapper .component.blog-list .component-content ul#blog-list li a .news-image img, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-image img {
    width: 100%;
    height: auto; }
  #wrapper .component.blog-list .component-content ul#blog-list li a .news-link, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link {
    display: inline-block;
    border-color: #ffffff;
    border-width: 3px;
    border-style: solid;
    padding: 0.9375rem 2rem;
    text-decoration: none;
    background-color: #ADDC91;
    text-align: center;
    transition: all 0.2s;
    -webkit-appearance: none;
    color: #0e1111;
    font-weight: 700;
    font-size: 1.33rem;
    float: right;
    padding: 0.9375rem 2rem; }
    #wrapper .component.blog-list .component-content ul#blog-list li a .news-link:not(.disabled), #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link:not(.disabled) {
      cursor: pointer; }
    #wrapper .component.blog-list .component-content ul#blog-list li a .news-link:not(.disabled):hover, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link:not(.disabled):hover {
      background-color: #ADDC91;
      color: #0e1111; }
      #wrapper .component.blog-list .component-content ul#blog-list li a .news-link:not(.disabled):hover a, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link:not(.disabled):hover a {
        text-decoration: none;
        color: #0e1111;
        text-shadow: none; }
    #wrapper .component.blog-list .component-content ul#blog-list li a .news-link a, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link a {
      color: #0e1111;
      text-decoration: none;
      background-color: transparent; }
    #wrapper .component.blog-list .component-content ul#blog-list li a .news-link:not(:empty), #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-link:not(:empty) {
      margin: 2.25rem 0 1rem; }
  #wrapper .component.blog-list .component-content ul#blog-list li a .news-text, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-text {
    text-align: left;
    color: #0e1111;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-top: 0.75rem; }
    @media all and (-ms-high-contrast: none) {
      #wrapper .component.blog-list .component-content ul#blog-list li a .news-text, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-text {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #wrapper .component.blog-list .component-content ul#blog-list li a .news-text, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-text {
        height: 6rem;
        overflow: hidden; } }
  #wrapper .component.blog-list .component-content ul#blog-list li a .news-date, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-date {
    padding: 0 0 0.5rem;
    text-align: left; }
  #wrapper .component.blog-list .component-content ul#blog-list li a .news-title, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-title {
    margin-top: 10px;
    display: block; }
    #wrapper .component.blog-list .component-content ul#blog-list li a .news-title .link, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-title .link {
      font-size: 1.333rem;
      text-decoration: none;
      color: #0e1111;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 800;
      font-style: normal;
      border-bottom: none; }
      @media all and (-ms-high-contrast: none) {
        #wrapper .component.blog-list .component-content ul#blog-list li a .news-title .link, #wrapper .component.latest-blog-posts .component-content ul#blog-list li a .news-title .link {
          font-weight: bolder; } }

.content .component.rich-text-list .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto; }
  .content .component.rich-text-list .component-content > ul {
    width: 100%; }
    .content .component.rich-text-list .component-content > ul:after {
      content: '';
      display: table;
      clear: both; }
    .content .component.rich-text-list .component-content > ul li {
      padding: 0 2rem 2rem; }
      .content .component.rich-text-list .component-content > ul li h2 {
        padding-bottom: 8px;
        font-weight: 800; }
      .content .component.rich-text-list .component-content > ul li h3 {
        font-weight: 700; }
      .content .component.rich-text-list .component-content > ul li:nth-child(n+1) {
        background-color: #D7D2CB; }
        .content .component.rich-text-list .component-content > ul li:nth-child(n+1) h2, .content .component.rich-text-list .component-content > ul li:nth-child(n+1) h3 {
          color: #0e1111; }
      .content .component.rich-text-list .component-content > ul li:nth-child(n+2) {
        background-color: #00558C; }
        .content .component.rich-text-list .component-content > ul li:nth-child(n+2) h2, .content .component.rich-text-list .component-content > ul li:nth-child(n+2) h3 {
          color: #ffffff; }
      .content .component.rich-text-list .component-content > ul li:nth-child(n+3) {
        background-color: #6BCABA; }
        .content .component.rich-text-list .component-content > ul li:nth-child(n+3) h2, .content .component.rich-text-list .component-content > ul li:nth-child(n+3) h3 {
          color: #0e1111; }
      .content .component.rich-text-list .component-content > ul li:nth-child(n+4) {
        background-color: #4EC3E0; }
        .content .component.rich-text-list .component-content > ul li:nth-child(n+4) h2, .content .component.rich-text-list .component-content > ul li:nth-child(n+4) h3 {
          color: #ffffff; }
    .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
    .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-end; }
      .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
      .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
        font-weight: 600; }
      @media screen and (min-width: 640px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
          width: calc(50% - 20px);
          max-width: 665px;
          float: left;
          margin: 10px; }
          .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
          .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
            display: inline-block;
            text-align: left;
            overflow: hidden;
            max-height: 106px;
            margin: 5px 0 17px; } }
      @media screen and (min-width: 790px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
          margin: 30px 0 17px; } }
      @media only screen and (min-width: 1200px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
          max-height: unset;
          margin: 42px 0 31px; } }
      @media screen and (max-width: 1000px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h2,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h2 {
          font-size: 1.83rem; } }
      @media only screen and (max-width: 767px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h2,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h2 {
          font-size: 2.2rem; }
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
          max-height: 70px;
          font-size: 1.25rem;
          overflow: hidden; } }
      @media screen and (max-width: 639px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h2,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h2 {
          font-size: 42px; }
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
          margin: 30px 0 17px; } }
      @media screen and (max-width: 460px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h2,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h2 {
          font-size: 2.8rem; }
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h3,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h3 {
          margin: 0.66667rem 0 0; } }
      @media screen and (max-width: 375px) {
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) h2,
        .content .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li h2 {
          font-size: 2.2rem; } }

.component.text-image-spot.timeline {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto;
  display: block; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.timeline {
      padding-bottom: 60px; }
      .component.text-image-spot.timeline .component-content {
        display: flex; }
        .component.text-image-spot.timeline .component-content .text-image-spot-image-container,
        .component.text-image-spot.timeline .component-content text-image-spot-text-container {
          display: block; } }
  .component.text-image-spot.timeline .component-content .text-image-spot-image img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0; }
    @media only screen and (min-width: 992px) {
      .component.text-image-spot.timeline .component-content .text-image-spot-image img {
        margin: 11px 0 11px; } }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.timeline .component-content .text-image-spot-image img {
        padding: 10px 0 15px; } }
  .component.text-image-spot.timeline .text-image-spot-text-container {
    margin: 0; }
    @media only screen and (min-width: 992px) {
      .component.text-image-spot.timeline .text-image-spot-text-container {
        padding-right: 0; } }
    @media only screen and (min-width: 992px) {
      .component.text-image-spot.timeline .text-image-spot-text-container .text-image-spot-text {
        max-width: 100%; } }
    .component.text-image-spot.timeline .text-image-spot-text-container .text-image-spot-text h2 {
      margin-top: 0;
      color: #00558C; }
    .component.text-image-spot.timeline .text-image-spot-text-container .text-image-spot-text h2 + h2 {
      margin-bottom: 20px; }
      @media only screen and (min-width: 992px) {
        .component.text-image-spot.timeline .text-image-spot-text-container .text-image-spot-text h2 + h2 {
          margin-bottom: 60px; } }

/*# sourceMappingURL=main.[hash].css.map */